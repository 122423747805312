import React, { useContext, useEffect, useRef, useState } from 'react';
import Cookies from 'js-cookie'
import { Badge, Button, Navbar, Nav } from 'reactstrap';
import { Link } from 'react-router-dom'
// import Switch from "react-switch"
import AppContext from '../../../context/Context'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
// import axios from 'axios';
// import apiurl from '../../../apiurl';
import logo from '../../../assets/img/logo-brand.png'

// System color theme
const systemTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;

const NavbarTop = () => {
    const { isDark, setIsDark } = useContext(AppContext)

    // const history = useHistory()

    // const [collapse, setCollapse] = useState(false)
    const [tooltip, setTooltip] = useState(false)
    const timeoutIDs = useRef([])

    // const toggleTheme = () => {
    //     setIsDark(!isDark)
    //     Cookies.set('theme', !isDark ? 'dark' : 'light', { sameSite: 'strict' })
    // }

    // const toggleCollapse = () => {
    //     setCollapse(!collapse)
    // }

    // Shows menu tooltip
    const showTooltip = () => {
        timeoutIDs.current.push(setTimeout(() => {
            setTooltip(true)
        }, 150))
    }

    // Hides menu tooltip
    const hideTooltip = () => {
        timeoutIDs.current.forEach(id => {
            clearTimeout(id)
        })
        timeoutIDs.current = []
        setTooltip(false)
    }

    useEffect(() => {
        if(!Cookies.get('theme') && typeof(Cookies.get('theme')) !== 'boolean') {
            setIsDark(systemTheme)
            Cookies.set('theme', systemTheme ? 'dark' : 'light', { sameSite: 'strict' })
        }
        // eslint-disable-next-line
    }, [])

    // const redirectLink = window.location.pathname === '/auth/login' ? '/apps' : window.location.pathname

    const logout = () => {
        // axios({
        //     method: 'POST',
        //     url: `${apiurl}/logout`,
        //     withCredentials: true
        // }).then(res => {
        //     Cookies.remove('user')
        //     history.replace('/auth/login', redirectLink)
        // }).catch(err => {
        //     console.error(err)
        // })
    }

    const renderNavbar = () => {
        return(
            <>
                <Navbar
                    className='navbar-main'
                    color="light"
                    dark={isDark}
                    expand="md"
                >
                    <div className='navbar-heading'>
                        <Link className='navbar-heading-link' to={Cookies.get('user') ? '/apps' : '/demo'}>
                            <img
                                alt='Logo'
                                src={logo}
                            />
                        </Link>
                        <Badge
                            className='badge-nav'
                            style={{ visibility: 'hidden' }}
                        >
                            v0.28.11 Alpha
                        </Badge>
                    </div>
                    {/* <Button
                        className='navbar-toggler'
                        color='light'
                        onClick={toggleCollapse}
                    >
                        <FontAwesomeIcon icon={faBars} />
                    </Button> */}
                    {/* <Collapse
                        isOpen={collapse}
                        navbar
                    > */}
                        <div className='navbar-search'>
                            {/* {Cookies.get('user') ?
                                <Input
                                    className='navbar-search-input'
                                    placeholder='Search'
                                    bsSize='lg'
                                />
                                : ''
                            } */}
                        </div>
                        <Nav
                            navbar
                        >
                            {/* <label className='navbar-switcher cursor-pointer'>
                                <h5 className='navbar-switcher-heading'>Toggle theme:</h5>
                                <Switch
                                    checked={isDark}
                                    checkedIcon={<FontAwesomeIcon
                                        className='navbar-switcher-checked'
                                        icon={faMoon}
                                        size='lg'
                                    />}
                                    uncheckedIcon={<FontAwesomeIcon
                                        className='navbar-switcher-unchecked'
                                        icon={faSun}
                                        size='lg'
                                    />}
                                    offColor='#000'
                                    onColor='#000'
                                    onChange={toggleTheme}
                                />
                            </label> */}
                            {Cookies.get('user') ?
                                <div className='navbar-logout'>
                                    <Button
                                        block
                                        color='falcon-default'
                                        onClick={logout}
                                        onMouseEnter={ showTooltip}
                                        onMouseLeave={hideTooltip}
                                        style={{ visibility: 'hidden' }}
                                    >
                                        {tooltip ? 'Logout' : ''} <FontAwesomeIcon icon={faSignOutAlt} />
                                    </Button>
                                </div>
                            : ''
                            }
                        </Nav>
                    {/* </Collapse> */}
                </Navbar>
            </>
        )
    }
    return(
        <>
            {renderNavbar()}
        </>
    )
}

export default NavbarTop;