import OpenReplay from '@openreplay/tracker'

const tracker = new OpenReplay({
    projectKey: process.env.REACT_APP_OPENREPLAY_KEY,
    ingestPoint: 'https://openreplay.querycharts.com/ingest',
    defaultInputMode: 0,
    obscureTextEmails: false,
    obscureInputEmails: false
})

export default tracker