import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import Cookies from 'js-cookie'

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Main from './Main';
import './helpers/initFA';

import tracker from './tracker'
import trackerAssist from '@openreplay/tracker-assist'
import trackerAxios from '@openreplay/tracker-axios'

tracker.use(trackerAxios({}))
tracker.use(trackerAssist({}))
tracker.start()

const redirectSection = window.location.pathname
if(!Cookies.get('user')) {
  if(!window.location.pathname.includes('/auth/')) {
    if(!(
        window.location.pathname.includes('/demo') ||
        window.location.pathname.includes('/share') ||
        window.location.pathname.includes('/public')
      )) {
      window.location = `/auth/login?redirect=${redirectSection}`
    }
  }
} else {
  tracker.setUserID(Cookies.get('user'))
  // tracker.setMetadata('plan', 'dev')
}

ReactDOM.render(
  <Main>
    <App />
  </Main>,
  document.getElementById('main')
);
