// import Cookies from 'js-cookie'
// const userTheme = Cookies.get('theme') === 'dark' ? true : false

export const version = '0.17.3';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const settings = {
  alerts: {
    joins: true,
    forms: true,
    metrics: true,
    dimensions: true,
    customs: true,
    filters: true,
    sorts: true
  },
  isFluid: false,
  isRTL: false,
  // isDark: userTheme, // Color theme
  isDark: false, // Color theme
  isTopNav: false,
  isVertical: true,
  get isCombo() {
    return this.isVertical && this.isTopNav;
  },
  showBurgerMenu: false, // controls showing vertical nav on mobile
  currency: '$',
  isNavbarVerticalCollapsed: false,
  navbarStyle: 'transparent',
  navbarDisplay: false
};
export default { version, navbarBreakPoint, topNavbarBreakpoint, settings };
